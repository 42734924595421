import axios from 'axios';
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

const IS_DEV = process.env.NODE_ENV === 'development';
const prod_user_url = 'https://api.onemillionhands.org/user';
const prod_content_url = 'https://api.onemillionhands.org/content';
interface UserFormData {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  password: string;
  userName: string;
}

const API = axios.create({
  baseURL: IS_DEV ? 'http://localhost:2000/user' : prod_user_url,
  withCredentials: true,
});

export const submitUserData = (newData: UserFormData) => {
  return API.post('/signup', newData);
};

export const submitLogin = (loginData?: { email: string, password: string }) => {
  if (!loginData) {
    throw new Error("No login data provided");
  }
  return API.post('/login', loginData);
};

export const validateToken = () => {
  return API.get('/volunteer/token');
};

export const submitLogout = () => {
  return API.get('/logout');
};
export const updateProfileService = () => {
  return API.get('/updateprofile');
}

export const submitPasswordReset = (data?: { email: string }) => {
  if (!data) {
    throw new Error("No email data provided");
  }
  return API.post('/password-reset', data);
};

const API2 = axios.create({
  baseURL: IS_DEV ? 'http://localhost:3001/' : prod_content_url,
  withCredentials: true,
});

export const getPosts = (platform: string) => {
  return API2.get(`/posts/platform/${platform === "Instagram" ? "Instgram" : platform}?page=1&pageSize=30`);
}

export const getImage = (id: string) => {
  return API2.get(`/posts/images/posts/${id}`)
}