import { Routes, Route, Navigate } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';

import SignIn from '../User-forms/SignIn';
import SignUp from '../User-forms/SignUp';
import AboutUs from '../Pages/AboutUs';
import PasswordReset from '../User-forms/ForgotPassword';
import MainPage from '../Pages/MainPage/MainPage';
import PublicRoute from './PublicRoute';
import EditProfile from '../User-forms/EditProfile';

export const MAIN_ROUTE = '/';
const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<PublicRoute element={SignIn}/>} />
      <Route path="/signup" element={<PublicRoute element={SignUp}/>} />
      <Route path="/aboutus" element={<AboutUs />} />
      <Route path={"/edit-profile"} element={<ProtectedRoute element={EditProfile} />} />
      <Route path="/forgot-password" element={<PublicRoute element={PasswordReset} />} /> {/* Add PasswordReset route */}
      <Route path={MAIN_ROUTE} element={<ProtectedRoute element={MainPage} />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default AppRoutes;
